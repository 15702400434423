<template>
  <b-row>
    <b-col
      cols="12"
      xl="10"
      class="mb-3"
    >
      <b-card :title="$t('settings.invoices.title')">
        {{ $t('settings.invoices.title') }}
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
