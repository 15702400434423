<template>
  <div>
    <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >
      <!-- billing information settings tab -->
      <b-tab>

        <!-- title -->
        <template #title>
          <span class="font-weight-bold">
            {{ $t('settings.billing-information.title') }}
          </span>
        </template>

        <billing-information-settings-tab />
      </b-tab>
      <!--/ billing information settings tab -->

      <!-- plan settings tab -->
      <b-tab disabled>

        <!-- title -->
        <template #title>
          <span class="font-weight-bold">
            {{ $t('settings.plan.title') }}
          </span>
          <b-badge
            variant="light-primary"
            class="ml-1"
          >
            Wkrótce
          </b-badge>
        </template>

        <plan-settings-tab />
      </b-tab>
      <!--/ plan settings tab -->

      <!-- invoices settings tab -->
      <b-tab disabled>

        <!-- title -->
        <template #title>
          <span class="font-weight-bold">
            {{ $t('settings.invoices.title') }}
          </span>
          <b-badge
            variant="light-primary"
            class="ml-1"
          >
            Wkrótce
          </b-badge>
        </template>

        <invoices-settings-tab />
      </b-tab>
      <!--/ invoices settings tab -->
    </b-tabs>
  </div>
</template>

<script>
import planAndBillingSetup from '@/views/organization/settings/plan-and-billing/planAndBillingSetup'
import BillingInformationSettingsTab
  from '@/views/organization/settings/plan-and-billing/tabs/BillingInformationSettingsTab'
import PlanSettingsTab from '@/views/organization/settings/plan-and-billing/tabs/PlanSettingsTab'
import InvoicesSettingsTab from '@/views/organization/settings/plan-and-billing/tabs/InvoicesSettingsTab'

export default {
  components: { InvoicesSettingsTab, BillingInformationSettingsTab, PlanSettingsTab },
  setup: planAndBillingSetup,
}
</script>

<style scoped>

</style>
