import { computed, ref } from '@vue/composition-api'
import { required, alphaNum } from '@validations'
import store from '@/store'
import login from '@/http/requests/auth/login'
import organization from '@/http/requests/organization'
import { parseRequestError } from '@/helpers/helpers'
import i18n from '@/libs/i18n'

export default () => {
  const me = computed(() => store.getters['auth/getUser'])
  const processing = ref(false)
  const loading = ref(true)
  const organizationId = ref(null)
  const organizationBillingAddress = ref({
    companyName: null,
    addressLine1: null,
    zipCode: null,
    city: null,
    taxIdentifier: null,
  })
  const isRegisteredCompany = ref(true)

  login.userOrganizationInfo()
    .then(res => {
      organizationId.value = res.data.id
      organizationBillingAddress.value = res.data.billingAddress
      isRegisteredCompany.value = !!res.data.billingAddress.taxIdentifier
    })
    .catch(err => parseRequestError(err))
    .finally(() => {
      loading.value = false
    })

  const onSubmit = () => {
    processing.value = true
    organization.patchOrganization(
      organizationId.value,
      {
        billingAddress: organizationBillingAddress.value,
      },
    )
      .then(() => {
        window.toast.notify.success(i18n.t('settings.billing-information.toast.updated'))
      })
      .catch(err => parseRequestError(err))
      .finally(() => {
        processing.value = false
      })
  }

  return {
    me,
    processing,
    loading,
    onSubmit,
    organizationBillingAddress,
    isRegisteredCompany,

    required,
    alphaNum,
  }
}
