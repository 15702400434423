<template>
  <div>
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refBillingInformationSettingsFormObserver"
    >
      <!-- Form -->
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col
            cols="12"
            xl="10"
            class="mb-3"
          >
            <b-card no-body>
              <b-card-body>
                <rc-overlay
                  :show="loading"
                  no-fade
                  variant="white"
                >
                  <b-card-title>
                    {{ $t('settings.billing-information.title') }}
                  </b-card-title>
                  <b-card-sub-title>
                    {{ $t('settings.billing-information.description') }}
                  </b-card-sub-title>

                  <address-form
                    v-model="organizationBillingAddress"
                    class="mb-1 mt-2"
                  />

                </rc-overlay>
              </b-card-body>

              <b-card-footer>

                <!-- Form Actions -->
                <div class="d-flex">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    type="submit"
                    :disabled="processing || loading"
                  >
                    {{ $t('shared.btn.update') }}
                  </b-button>
                </div>
              </b-card-footer>
            </b-card>

          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { VBModal } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import billingInformationSettingsTabSetup
  from '@/views/organization/settings/plan-and-billing/tabs/billingInformationSettingsTabSetup'
import RcOverlay from '@/layouts/components/rc/RcOverlay'
import AddressForm from '@/views/_global/AddressForm'

export default {
  components: {
    AddressForm,
    RcOverlay,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  setup: billingInformationSettingsTabSetup,
}
</script>

<style scoped>

</style>
